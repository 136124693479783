import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { InhibitorsStoreContext } from "../../stores/inhibitors.store";
import { UserDataStoreContext } from "../../stores/userdata.store";
import { WebSocketStoreContext } from "../../stores/websocket.store";
import Button from "react-bootstrap/Button";
import { setInhibitorAutotrack, setInhibitorStatus } from "../../api";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Form, ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { autorun, toJS } from "mobx";
import { GroupDataStoreContext } from "../../stores/groupdata.store";

const InhibitorComp = observer((props: any) => {
  const [t, i18n] = useTranslation("common");

  const inhibitorsStore = useContext(InhibitorsStoreContext);
  const [autoTrack, setAutoTrack] = useState(
    inhibitorsStore.selectedInhibitor.autoInhibition ?? false
  );
  const usersdataStore = useContext(UserDataStoreContext);
  const groupdataStore = useContext(GroupDataStoreContext);

  const realtimeStore = useContext(WebSocketStoreContext);
  const [mustWait, setMustWait] = useState(false);
  const [directionalInhibitorDirections, setDirectionalInhibitorDirections] =
    useState<any>({
      n: false,
      e: false,
      o: false,
      s: false,
    });
  const [sectorsEnabled, setDerectionsEnabled] = useState<any>({
    n: inhibitorsStore.selectedInhibitor.gpios.output
      .filter((pin: any) => pin.alias.includes("4"))
      .every((pin: any) => pin.enabled === true),
    e: inhibitorsStore.selectedInhibitor.gpios.output
      .filter((pin: any) => pin.alias.includes("1"))
      .every((pin: any) => pin.enabled === true),
    o: inhibitorsStore.selectedInhibitor.gpios.output
      .filter((pin: any) => pin.alias.includes("3"))
      .every((pin: any) => pin.enabled === true),
    s: inhibitorsStore.selectedInhibitor.gpios.output
      .filter((pin: any) => pin.alias.includes("2"))
      .every((pin: any) => pin.enabled === true),
  });
  const websocketStore = useContext(WebSocketStoreContext);
  const [burst, setBurst] = useState(false);
  const [autoInhibitionRadius, setAutoInhibitionRadius] = useState(
    inhibitorsStore.selectedInhibitor.inhibitionRadius
  );
  const [inhibitionMode, setInhibitionMode] = useState<any>({
    CONTROL: false,
    GPS: false,
  });
  const [inhibitionIndividualModules, setInhibitionIndividualModules] =
    useState<Array<String>>([]);
  const [showIndividualInhibitionModules, setShowIndividualInhibitionModules] =
    useState(false);

  const directionsDic: any = {
    n: 4,
    e: 1,
    s: 2,
    o: 3,
  };
  const [selectedDrones, setSelectedDrones] = useState<any>([]);

  const currentGroupId = groupdataStore.group._id;

  const groupsWithAiPermissions = [
    "6023be1709a225901441e3e6", // ASDT devserver
    "5f05780ab902bc3a58e303bb", // ASDT
    "653137fffe7e47943e1f5dd9", // UAV Latam
    "65313957fe7e47943e1fe015", // Proyecto Cerberus
    "63fcd60b4200739cd90ff25d", // Dilectro
    "63fcd6794200739cd910463c", // Ukraine I
    "652e4c4119f32a04b9b8143f", // Ukraine II
  ];

  // Function to check if the groupID is in the idsArray
  const autoInhibitionPermission = groupsWithAiPermissions.includes(
    currentGroupId.toString()
  );

  useEffect(() => {
    setShowIndividualInhibitionModules(false);
  }, [inhibitorsStore.selectedInhibitor["_id"]]);

  const onActivateInhibition = async (type: string, toAPI: any) => {
    setMustWait(true);
    setTimeout(() => setMustWait(false), 3000);
    let response = await setInhibitorStatus(
      toAPI,
      usersdataStore.user.group,
      inhibitorsStore.selectedInhibitor._id
    );
    if (!response[0]) {
      alert(t("widgets.inhibitor.alert-stop-inhibition"));
    }
    //clearState();
    setTimeout(
      () => inhibitorsStore.getGroupInhibitors(usersdataStore.user.group),
      2000
    );
  };

  function haveSameElements(arr1: any, arr2: any) {
    // Convert both arrays to Sets to eliminate duplicates
    const set1: any = new Set(arr1);
    const set2: any = new Set(arr2);

    // If their sizes are different, they don't have the same elements
    if (set1.size !== set2.size) {
      return false;
    }

    // Check every element in set1 to see if it exists in set2
    for (let element of set1) {
      if (!set2.has(element)) {
        return false; // If an element in set1 is not in set2, arrays don't have the same elements
      }
    }

    // If we reach here, all elements match
    return true;
  }

  useEffect(() => {
    // Create a reaction that automatically runs when `activeDetections` changes
    const dispose = autorun(() => {
      let nonFriendDrones: any = [];
      if (websocketStore.activeDetections) {
        Object.values(websocketStore.activeDetections).forEach((det: any) => {
          const detectionLog = det[1]; // Assuming this is how your data structure is set up
          const lastDetection = detectionLog[detectionLog.length - 1];
          const sn = lastDetection.drone.sn;
          if (sn && lastDetection.drone.type !== "FRIEND") {
            nonFriendDrones.push(sn);
          }
        });

        // This will now trigger a re-render whenever `activeDetections` changes
        let difference = !haveSameElements(nonFriendDrones, selectedDrones);

        difference && setSelectedDrones(nonFriendDrones);
      }
    });

    // Cleanup autorun when component unmounts or dependencies change
    return () => dispose();
  }, [websocketStore, selectedDrones]);

  useEffect(() => {
    // Assuming you want to run the following part only when `autoTrack` changes
    if (!autoTrack) {
      props.setShowAutoInhibitionRadius(false);
    } else {
      props.setShowAutoInhibitionRadius(true);
    }
  }, [autoTrack, props]);

  //useEffect(() => {
  //  console.log("SD changes ->", selectedDrones);
  //}, [selectedDrones]);

  // useEffect(()=>{
  //     const isInhibiting = inhibitorsStore.selectedInhibitor.inhibitionInProcess

  //     if (autoTrack && isInhibiting) {
  //         console.log("triggerin handleInhibition");

  //         handleInhibition()
  //     }
  // }, [autoTrack, selectedDrones, inhibitorsStore.selectedInhibitor.inhibitionInProcess])

  //console.log("AutoTrack", autoTrack);

  const handleInhibition = async () => {
    try {
      if (autoTrack) {
        //alert("Función de inhibición automática desactivada")
        if (autoInhibitionRadius < 50 || autoInhibitionRadius > 1500) {
          alert(t("widgets.inhibitor.alert-invalid-radius"));
          return;
        }

        // console.log("!!! selected Drones", selectedDrones.length);
        // console.log("!!! autoinhibitionradius", autoInhibitionRadius);

        if (!autoInhibitionRadius) {
          alert(t("widgets.inhibitor.alert-invalid-parameters"));
          return;
        }
        setMustWait(true);
        setTimeout(() => setMustWait(false), 3000);
        let toAPI = {
          autoInhibition: true,
          autoInhibitionRadius: autoInhibitionRadius,
          autoInhibitionModes: Object.keys(inhibitionMode).filter(
            (mod) => inhibitionMode[mod]
          ),
        };
        let response = await setInhibitorAutotrack(
          usersdataStore.user.group,
          inhibitorsStore.selectedInhibitor._id,
          toAPI
        );
        if (!response[0]) {
          alert(t("widgets.inhibitor.alert-start-inhibition"));
        }
        setTimeout(
          () => inhibitorsStore.getGroupInhibitors(usersdataStore.user.group),
          2000
        );
        return;
      }
      if (
        inhibitorsStore.selectedInhibitor.inhibitionMode == "omni" &&
        showIndividualInhibitionModules
      ) {
        let toAPI = {
          inhibitionModules: inhibitionIndividualModules,
          burst: burst,
          sectors: [],
        };
        if (toAPI.inhibitionModules.length <= 0) {
          alert(t("widgets.inhibitor.alert-inhibition-mode"));
          return;
        }
        onActivateInhibition("", toAPI);
        return;
      }
      //ALERT SI NO TIENE SECTORES O MODOS
      let toAPI = {
        inhibitionModules: Object.keys(inhibitionMode).filter(
          (mod) => inhibitionMode[mod]
        ),
        burst: burst,
        sectors: Object.keys(directionalInhibitorDirections)
          .filter((dir) => directionalInhibitorDirections[dir])
          .map((dir) => {
            return directionsDic[dir];
          }),
      };
      if (toAPI.inhibitionModules.length <= 0) {
        alert(t("widgets.inhibitor.alert-inhibition-mode"));
        return;
      }
      if (
        inhibitorsStore.selectedInhibitor.inhibitionMode === "sect" &&
        toAPI.sectors.length <= 0
      ) {
        alert(t("widgets.inhibitor.alert-inhibition-sectors"));
        return;
      }
      onActivateInhibition("", toAPI);
    } catch (err) {
      console.error(err);
      return;
    }
  };

  const stopInhibition = async () => {
    try {
      setMustWait(true);
      setTimeout(() => setMustWait(false), 3000);

      let param: any = {
        inhibitionModules: Object.keys(inhibitionMode).filter(
          (mod) => inhibitionMode[mod]
        ),
        burst: burst,
        sectors: Object.keys(directionalInhibitorDirections)
          .filter((dir) => directionalInhibitorDirections[dir])
          .map((dir) => {
            return directionsDic[dir];
          }),
      };

      if (inhibitorsStore.selectedInhibitor.inhibitionInProcess)
        param.stop = true;

      let response = await setInhibitorStatus(
        param,
        usersdataStore.user.group,
        inhibitorsStore.selectedInhibitor._id
      );
      if (!response[0]) {
        alert(t("widgets.inhibitor.alert-stop-inhibition"));
      } else {
        setInhibitionMode({
          GPS: false,
          CONTROL: false,
        });
        setDirectionalInhibitorDirections({
          n: false,
          s: false,
          e: false,
          o: false,
        });
      }

      await stopAutoInhibition();
    } catch (err) {
      console.error(err);
      return;
    }
  };

  const stopAutoInhibition = async () => {
    try {
      setMustWait(true);
      setTimeout(() => setMustWait(false), 3000);
      let response = await setInhibitorAutotrack(
        usersdataStore.user.group,
        inhibitorsStore.selectedInhibitor._id,
        {}
      );
      if (!response[0]) {
      } else {
        setInhibitionMode({
          GPS: false,
          CONTROL: false,
        });
        setDirectionalInhibitorDirections({
          n: false,
          s: false,
          e: false,
          o: false,
        });
      }
    } catch (err) {
      console.error(err);
      return;
    }
  };

  function changeInhibitionRadius(value: any) {
    props.setAutoInhibitionRadius(value);
    setAutoInhibitionRadius(value);
  }

  function clearState() {
    setDirectionalInhibitorDirections({
      n: false,
      e: false,
      o: false,
      s: false,
    });
    setDerectionsEnabled({
      n: inhibitorsStore.selectedInhibitor.gpios.output
        .filter((pin: any) => pin.alias.includes("4"))
        .every((pin: any) => pin.enabled === true),
      e: inhibitorsStore.selectedInhibitor.gpios.output
        .filter((pin: any) => pin.alias.includes("1"))
        .every((pin: any) => pin.enabled === true),
      o: inhibitorsStore.selectedInhibitor.gpios.output
        .filter((pin: any) => pin.alias.includes("3"))
        .every((pin: any) => pin.enabled === true),
      s: inhibitorsStore.selectedInhibitor.gpios.output
        .filter((pin: any) => pin.alias.includes("2"))
        .every((pin: any) => pin.enabled === true),
    });
    setBurst(false);
    setAutoInhibitionRadius(inhibitorsStore.selectedInhibitor.inhibitionRadius);
    setInhibitionMode({
      CONTROL: false,
      GPS: false,
    });
    setInhibitionIndividualModules([]);
  }

  function renderIndividualInhibitionModules() {
    let controlModules: Array<String> = [];
    inhibitorsStore.selectedInhibitor.gpios.output.forEach((pin: any) => {
      if (
        pin.enabled &&
        pin.alias.includes("CONTROL") &&
        !controlModules.includes(pin.alias)
      ) {
        controlModules.push(pin.alias);
      }
    });

    let gpsModules: Array<String> = [];
    inhibitorsStore.selectedInhibitor.gpios.output.forEach((pin: any) => {
      if (
        pin.enabled &&
        pin.alias.includes("GPS") &&
        !gpsModules.includes(pin.alias)
      ) {
        gpsModules.push(pin.alias);
      }
    });

    function handleModuleSelection(alias: String) {
      if (inhibitionIndividualModules.includes(alias)) {
        setInhibitionIndividualModules((prev: Array<String>) =>
          prev.filter((aux) => aux != alias)
        );
      } else {
        setInhibitionIndividualModules((prev: Array<String>) => [
          ...prev,
          alias,
        ]);
      }
    }

    return (
      <div
        className={mustWait ? "inhibitors-spinner-flexbox" : undefined}
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "0 1rem",
          minWidth: "50%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingRight: ".75rem",
          }}
        >
          <span>{t("widgets.inhibitor.control")}</span>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {controlModules.map((module: String) => {
              return (
                <OverlayTrigger
                  key={"control_inhibition_" + module}
                  placement="bottom"
                  overlay={
                    <Tooltip id="centerLocation">
                      {t("widgets.inhibitor.inhibition-mode-hover")}{" "}
                      {module.replace("_", " ")}
                    </Tooltip>
                  }
                >
                  <Button
                    style={{
                      marginRight: "0.5rem",
                      width: "auto",
                      boxShadow: inhibitionIndividualModules.includes(module)
                        ? "0 0 5px 3px gray"
                        : "none",
                    }}
                    onClick={() => {
                      handleModuleSelection(module);
                    }}
                    variant={
                      inhibitorsStore.selectedInhibitor.gpios.output.some(
                        (pin: any) => pin.active && pin.alias.includes(module)
                      )
                        ? "danger"
                        : "outline-secondary"
                    }
                  >
                    {module.split("_")[1]}
                  </Button>
                </OverlayTrigger>
              );
            })}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: ".75rem",
          }}
        >
          <span>{t("widgets.inhibitor.gps")}</span>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {gpsModules.map((module: String) => {
              return (
                <OverlayTrigger
                  key={"gps_inhibition_" + module}
                  placement="top"
                  overlay={
                    <Tooltip id="centerLocation">
                      {t("widgets.inhibitor.inhibition-mode-hover")}{" "}
                      {module.replace("_", " ")}
                    </Tooltip>
                  }
                >
                  <Button
                    style={{
                      marginRight: "0.5rem",
                      width: "auto",
                      boxShadow: inhibitionIndividualModules.includes(module)
                        ? "0 0 5px 3px gray"
                        : "none",
                    }}
                    onClick={() => {
                      handleModuleSelection(module);
                    }}
                    variant={
                      inhibitorsStore.selectedInhibitor.gpios.output.some(
                        (pin: any) => pin.active && pin.alias.includes(module)
                      )
                        ? "danger"
                        : "outline-secondary"
                    }
                  >
                    {module.split("_")[1]}
                  </Button>
                </OverlayTrigger>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  function renderOmniInhibition() {
    const isInhibitionActive =
      inhibitorsStore.selectedInhibitor.inhibitionInProcess;

    // If automatic inhibition is on and active, show only title and stop button.
    if (inhibitorsStore.selectedInhibitor.autoInhibition) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          
          <Button
            onClick={stopAutoInhibition}
            style={{
              padding: ".5rem 1rem",
              boxShadow: "none",
              height: "fit-content",
              width: "fit-content",
            }}
            variant={"danger"}
          >
            {t("Stop automatic netralization")}
          </Button>
        </div>
      );
    }

    // Otherwise, render the full inhibition controls.
    let hasIndModules = inhibitorsStore.selectedInhibitor.gpios.output.every(
      (pin: any) => (pin.enabled ? pin.alias.includes("_") : true)
    );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Autotrack toggle (automatic inhibition) */}
        {autoInhibitionPermission && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "1rem",
              marginBottom: "1rem",
              width: "100%",
            }}
          >
            {/* Toggle switch */}
            <Form.Check
              type="switch"
              id="autotrack-switch"
              label={"Activación automática"}
              checked={autoTrack}
              onChange={() => {
                setAutoTrack((prev: any) => !prev);
                clearState();
              }}
            />
          </div>
        )}

        {/* Hide neutralization by channel toggle if autotrack is true */}
        {hasIndModules && !autoTrack && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "1rem",
              marginBottom: "1rem",
              width: "100%",
            }}
          >
            <Form.Check
              type="switch"
              id="individual-inhibition-switch"
              label={t("widgets.inhibitor.inhibition-by-modules")}
              checked={showIndividualInhibitionModules}
              onChange={() => {
                setShowIndividualInhibitionModules((prev) => !prev);
                clearState();
              }}
              disabled={autoTrack}
            />
          </div>
        )}

        {/* Conditional rendering based on individual inhibition toggle */}
        {showIndividualInhibitionModules && !autoTrack ? (
          <>{renderIndividualInhibitionModules()}</>
        ) : (
          <div
            className={mustWait ? "inhibitors-spinner-flexbox" : undefined}
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "0 1rem",
              minWidth: "50%",
              width: "100%",
            }}
          >
            {/* Control Inhibition and GPS Inhibition */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingRight: ".75rem",
              }}
            >
              <span>{t("widgets.inhibitor.control")}</span>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {/* Control Inhibition Button */}
                <OverlayTrigger
                  key={"control_inhibition"}
                  placement="bottom"
                  overlay={
                    <Tooltip id="centerLocation">
                      {t("widgets.inhibitor.control1-hover")}
                    </Tooltip>
                  }
                >
                  <Button
                    style={{
                      marginRight: "0.5rem",
                      boxShadow:
                        inhibitionMode["CONTROL"] && !burst
                          ? "0 0 5px 3px gray"
                          : "none",
                      whiteSpace: "nowrap",
                      width: "fit-content",
                    }}
                    onClick={() => {
                      setInhibitionMode({
                        ...inhibitionMode,
                        CONTROL:
                          inhibitionMode["CONTROL"] && !burst ? false : true,
                      });
                      setBurst(false);
                    }}
                    variant={
                      inhibitorsStore.selectedInhibitor.gpios.output.some(
                        (pin: any) =>
                          pin.active &&
                          !pin.burstActive &&
                          pin.alias.includes("CONTROL")
                      )
                        ? "danger"
                        : "outline-secondary"
                    }
                  >
                    {t("widgets.inhibitor.control-mode-1")}
                  </Button>
                </OverlayTrigger>

                {/* Control Mode Burst Button */}
                {!inhibitorsStore.selectedInhibitor.isOld &&
                inhibitorsStore.selectedInhibitor.gpios.output.some(
                  (pin: any) => pin.burst
                ) ? (
                  <OverlayTrigger
                    key={"gps_inihibition"}
                    placement="bottom"
                    overlay={
                      <Tooltip id="centerLocation">
                        {t("widgets.inhibitor.control2-hover")}
                      </Tooltip>
                    }
                  >
                    <Button
                      style={{
                        marginLeft: "0.5rem",
                        boxShadow:
                          inhibitionMode["CONTROL"] && burst
                            ? "0 0 5px 3px gray"
                            : "none",
                        whiteSpace: "nowrap",
                        width: "fit-content",
                      }}
                      onClick={() => {
                        setInhibitionMode({
                          ...inhibitionMode,
                          CONTROL:
                            inhibitionMode["CONTROL"] && burst ? false : true,
                        });
                        setBurst(!burst);
                      }}
                      variant={
                        inhibitorsStore.selectedInhibitor.gpios.output.some(
                          (pin: any) =>
                            pin.active &&
                            pin.burstActive &&
                            pin.alias.includes("CONTROL")
                        )
                          ? "danger"
                          : "outline-secondary"
                      }
                    >
                      {t("widgets.inhibitor.control-mode-2")}
                    </Button>
                  </OverlayTrigger>
                ) : null}
              </div>
            </div>

            {/* GPS Inhibition Button */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: ".75rem",
              }}
            >
              <span>{t("widgets.inhibitor.gps")}</span>
              <OverlayTrigger
                key={"gps_inihibition"}
                placement="bottom"
                overlay={
                  <Tooltip id="centerLocation">
                    {t("widgets.inhibitor.gps-hover")}
                  </Tooltip>
                }
              >
                <Button
                  style={{
                    boxShadow: inhibitionMode["GPS"]
                      ? "0 0 5px 3px gray"
                      : "none",
                    whiteSpace: "nowrap",
                    width: "fit-content",
                  }}
                  onClick={() =>
                    setInhibitionMode({
                      ...inhibitionMode,
                      GPS: !inhibitionMode["GPS"],
                    })
                  }
                  variant={
                    inhibitorsStore.selectedInhibitor.gpios.output.some(
                      (pin: any) => pin.active && pin.alias.includes("GPS")
                    )
                      ? "danger"
                      : "outline-secondary"
                  }
                >
                  {t("widgets.inhibitor.gps-mode-1")}
                </Button>
              </OverlayTrigger>
            </div>
          </div>
        )}

        {/* Inhibition radius input */}
        {autoTrack && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1rem",
            }}
          >
            <span>{t("widgets.inhibitor.inhibition-radius")}</span>
            <span>(50m - 1500m)</span>
            <input
              value={autoInhibitionRadius}
              type="number"
              onChange={(v) => changeInhibitionRadius(v.target.value)}
            />
          </div>
        )}

        {/* Start/Stop Inhibition Button */}
        <OverlayTrigger
          key={"gps_inihibition"}
          placement="top"
          overlay={
            <Tooltip id="centerLocation">
              {t("widgets.inhibitor.btn-start-hover")}
            </Tooltip>
          }
        >
          <Button
            onClick={() => {
              if (isInhibitionActive) {
                // Stop inhibition based on autotrack state
                autoTrack ? stopAutoInhibition() : stopInhibition();
              } else {
                // Start inhibition based on autotrack state
                handleInhibition();
              }
            }}
            style={{
              padding: ".5rem 1rem",
              boxShadow: "none",
              height: "fit-content",
              width: "fit-content",
            }}
            variant={"danger"}
          >
            {autoTrack
              ? isInhibitionActive
                ? `Stop automatic neutralization`
                  : `Start automatic neutralization`
              : inhibitorsStore.selectedInhibitor.gpios.output.some(
                  (pin: any) => pin.active
                )
              ? `Stop ${autoTrack ? "automatic" : ""} neutralization`
              : `Start ${autoTrack ? "automatic" : ""} neutralization`}
          </Button>
        </OverlayTrigger>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        width: "100%",
        height: "100%",
        borderRadius: "7px",
        padding: "10px 2rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {inhibitorsStore.selectedInhibitor ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => (inhibitorsStore.selectedInhibitor = null)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 .5rem",
            }}
          >
            <h5>{t("widgets.inhibitor.header")}</h5>
            <h5>
              <b>{inhibitorsStore.selectedInhibitor.name}</b>
            </h5>
          </div>
          {inhibitorsStore.selectedInhibitor.inhibitionMode === "sect" ? (
            mustWait ? (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Spinner animation="border" variant="primary" />
              </div>
            ) : inhibitorsStore.selectedInhibitor.gpios.output.filter(
                (config: any) => config.active
              ).length > 0 ||
              inhibitorsStore.selectedInhibitor.inhibitionInProcess ||
              inhibitorsStore.selectedInhibitor.autoInhibition ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  height: "100%",
                  paddingBottom: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "left",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-around",
                      alignItems: "center",
                      padding: "0 2rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        transform: "rotate(45deg)",
                        width: "fit-content",
                        position: "relative",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <DirectionalInhibitorSector2
                          direction="N"
                          active={
                            inhibitorsStore.selectedInhibitor.gpios.output.filter(
                              (config: any) =>
                                config.active && config.alias.includes("4")
                            ).length > 0
                          }
                          enabled={sectorsEnabled["n"]}
                        >
                          <span style={{ transform: "rotate(-45deg)" }}>N</span>
                        </DirectionalInhibitorSector2>
                        <DirectionalInhibitorSector2
                          direction="E"
                          active={
                            inhibitorsStore.selectedInhibitor.gpios.output.filter(
                              (config: any) =>
                                config.active && config.alias.includes("1")
                            ).length > 0
                          }
                          enabled={sectorsEnabled["e"]}
                        >
                          <span style={{ transform: "rotate(-45deg)" }}>E</span>
                        </DirectionalInhibitorSector2>
                      </div>
                      <div style={{ display: "flex" }}>
                        <DirectionalInhibitorSector2
                          direction="O"
                          active={
                            inhibitorsStore.selectedInhibitor.gpios.output.filter(
                              (config: any) =>
                                config.active && config.alias.includes("3")
                            ).length > 0
                          }
                          enabled={sectorsEnabled["o"]}
                        >
                          <span style={{ transform: "rotate(-45deg)" }}>O</span>
                        </DirectionalInhibitorSector2>
                        <DirectionalInhibitorSector2
                          direction="S"
                          active={
                            inhibitorsStore.selectedInhibitor.gpios.output.filter(
                              (config: any) =>
                                config.active && config.alias.includes("2")
                            ).length > 0
                          }
                          enabled={sectorsEnabled["s"]}
                        >
                          <span style={{ transform: "rotate(-45deg)" }}>S</span>
                        </DirectionalInhibitorSector2>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <OverlayTrigger
                        key={"gps_inihibition"}
                        placement="bottom"
                        overlay={
                          <Tooltip id="centerLocation">
                            {t("widgets.inhibitor.btn-stop-hover")}
                          </Tooltip>
                        }
                      >
                        <Button
                          onClick={() =>
                            autoTrack ? stopAutoInhibition() : stopInhibition()
                          }
                          style={{
                            padding: "1rem",
                            boxShadow: "none",
                            height: "fit-content",
                            width: "fit-content",
                          }}
                          variant={"danger"}
                        >
                          {t("widgets.inhibitor.btn-stop")}
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  paddingBottom: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "left",
                  }}
                >
                  <div style={{ width: "fit-content" }}>
                    {autoInhibitionPermission && (
                      <ListGroup.Item
                        onClick={() => {
                          setAutoTrack(!autoTrack);
                        }}
                        style={{ border: "none" }}
                      >
                        <Form.Check
                          type="switch"
                          label={
                            props.trackedDrone
                              ? "Activación sectorial automática " +
                                props.trackedDrone
                              : "Activación sectorial automática"
                          }
                          checked={autoTrack}
                          style={{ cursor: "pointer" }}
                        />
                      </ListGroup.Item>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-around",
                      alignItems: "center",
                      padding: "0 2rem",
                    }}
                  >
                    {autoTrack ? (
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                      ></div>
                    ) : (
                      <InhibitionDirectionsComponent
                        sectorsEnabled={sectorsEnabled}
                        directionalInhibitorDirections={
                          directionalInhibitorDirections
                        }
                        setDirectionalInhibitorDirections={
                          setDirectionalInhibitorDirections
                        }
                      />
                    )}
                    <div
                      className={
                        mustWait ? "inhibitors-spinner-flexbox" : undefined
                      }
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "0 1rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1rem",
                        }}
                      >
                        <span>{t("widgets.inhibitor.control")}</span>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <OverlayTrigger
                            key={"control_inhibition"}
                            placement="bottom"
                            overlay={
                              <Tooltip id="centerLocation">
                                {t("widgets.inhibitor.control1-hover")}
                              </Tooltip>
                            }
                          >
                            <Button
                              style={{
                                marginRight: "0.5rem",
                                boxShadow:
                                  inhibitionMode["CONTROL"] && !burst
                                    ? "0 0 5px 3px gray"
                                    : "none",
                              }}
                              onClick={() => {
                                setInhibitionMode({
                                  ...inhibitionMode,
                                  CONTROL:
                                    inhibitionMode["CONTROL"] && !burst
                                      ? false
                                      : true,
                                });
                                setBurst(false);
                              }}
                              variant={
                                inhibitorsStore.selectedInhibitor.gpios.output.some(
                                  (pin: any) =>
                                    pin.active &&
                                    !pin.burstActive &&
                                    pin.alias.includes("CONTROL")
                                )
                                  ? "danger"
                                  : "outline-secondary"
                              }
                            >
                              {t("widgets.inhibitor.control-mode-1")}
                            </Button>
                          </OverlayTrigger>
                          {!inhibitorsStore.selectedInhibitor.gpios.output.some(
                            (pin: any) => pin.burst
                          ) ? null : (
                            <OverlayTrigger
                              key={"gps_inihibition"}
                              placement="bottom"
                              overlay={
                                <Tooltip id="centerLocation">
                                  {t("widgets.inhibitor.control2-hover")}
                                </Tooltip>
                              }
                            >
                              <Button
                                style={{
                                  marginLeft: "0.5rem",
                                  boxShadow:
                                    inhibitionMode["CONTROL"] && burst
                                      ? "0 0 5px 3px gray"
                                      : "none",
                                }}
                                onClick={() => {
                                  setInhibitionMode({
                                    ...inhibitionMode,
                                    CONTROL:
                                      burst && inhibitionMode["CONTROL"]
                                        ? false
                                        : true,
                                  });
                                  setBurst(!burst);
                                }}
                                variant={
                                  inhibitorsStore.selectedInhibitor.gpios.output.some(
                                    (pin: any) =>
                                      pin.active &&
                                      pin.burstActive &&
                                      pin.alias.includes("CONTROL")
                                  )
                                    ? "danger"
                                    : "outline-secondary"
                                }
                              >
                                {t("widgets.inhibitor.control-mode-2")}
                              </Button>
                            </OverlayTrigger>
                          )}
                        </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{t("widgets.inhibitor.gps")}</span>
                        <OverlayTrigger
                          key={"gps_inihibition"}
                          placement="bottom"
                          overlay={
                            <Tooltip id="centerLocation">
                              {t("widgets.inhibitor.gps-hover")}
                            </Tooltip>
                          }
                        >
                          <Button
                            style={{
                              boxShadow: inhibitionMode["GPS"]
                                ? "0 0 5px 3px gray"
                                : "none",
                            }}
                            onClick={() =>
                              setInhibitionMode({
                                ...inhibitionMode,
                                GPS: !inhibitionMode["GPS"],
                              })
                            }
                            variant={
                              inhibitorsStore.selectedInhibitor.gpios.output.some(
                                (pin: any) =>
                                  pin.active && pin.alias.includes("GPS")
                              )
                                ? "danger"
                                : "outline-secondary"
                            }
                          >
                            {t("widgets.inhibitor.gps-mode-1")}
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {autoTrack ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "1rem",
                          }}
                        >
                          <span>
                            {t("widgets.inhibitor.inhibition-radius")}
                          </span>
                          <span>(50m - 1500m)</span>
                          <input
                            value={autoInhibitionRadius}
                            type="number"
                            onChange={(v: any) => {
                              changeInhibitionRadius(v.target.value);
                            }}
                          />
                        </div>
                      ) : null}
                      <OverlayTrigger
                        key={"gps_inihibition"}
                        placement="bottom"
                        overlay={
                          <Tooltip id="centerLocation">
                            {t("widgets.inhibitor.btn-start-hover")}
                          </Tooltip>
                        }
                      >
                        <Button
                          onClick={() => handleInhibition()}
                          style={{
                            padding: ".5rem 1rem",
                            boxShadow: "none",
                            height: "fit-content",
                            width: "fit-content",
                          }}
                          variant={"danger"}
                        >
                          {t("widgets.inhibitor.btn-start")}
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <>
              <div
                className={
                  mustWait
                    ? "inhibitors-spinner-flexbox"
                    : "inhibitors-buttons-grid"
                }
                style={{ display: "flex", alignItems: "center" }}
              >
                {mustWait ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <>{renderOmniInhibition()}</>
                )}
              </div>
            </>
          )}
        </>
      ) : null}
    </div>
  );
});

const SNItem = styled.span`
  padding: 0rem 1rem;
  background-color: ${(props: { selected: Boolean }) =>
    props.selected ? "#007bff" : null};
  color: ${(props: { selected: Boolean }) => (props.selected ? "white" : null)};

  &:hover {
    background-color: #007bff;
    color: white;
  }
`;

const DirectionalInhibitorSector = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${(props: {
    direction: string;
    active: Boolean;
    enabled: Boolean;
  }) => (!props.enabled ? "rgba(0,0,0,.2)" : "white")}; //${(props: {
    direction: string;
    active: Boolean;
    enabled: Boolean;
  }) => (props.active ? "#007bff" : "white")};
  border-radius: ${(props: {
    direction: string;
    active: Boolean;
    enabled: Boolean;
  }) =>
    props.direction === "N"
      ? "50px 0 20px 0"
      : props.direction === "E"
      ? "0px 50px 0px 20px"
      : props.direction === "O"
      ? "0px 20px 0px 50px"
      : "20px 0 50px 0px"};
  margin: 2px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: ${(props: { direction: string; active: Boolean; enabled: Boolean }) =>
    !props.enabled ? null : "pointer"};
  border: 1px solid
    ${(props: { direction: string; active: Boolean; enabled: Boolean }) =>
      props.active ? "#007bff" : "rgba(0,0,0,.125)"};

  ${(props) =>
    props.enabled
      ? `&: hover{
        background - color: white; //${(props: {
          direction: string;
          active: Boolean;
          enabled: Boolean;
        }) => (!props.active ? "#007bff" : "white")};
        //color: ${(props: {
          direction: string;
          active: Boolean;
          enabled: Boolean;
        }) => (props.active ? "#212529" : "white")};
        border: 1px solid ${(props: {
          direction: string;
          active: Boolean;
          enabled: Boolean;
        }) => (!props.active ? "#007bff" : "rgba(0,0,0,.125)")};
    }`
      : ""}
`;

const DirectionalInhibitorSector2 = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${(props: {
    direction: string;
    active: Boolean;
    enabled: Boolean;
  }) => (props.active ? "rgba(0,0,0,.3)" : "white")}; //${(props: {
    direction: string;
    active: Boolean;
    enabled: Boolean;
  }) => (props.active ? "#007bff" : "white")};
  border-radius: ${(props: {
    direction: string;
    active: Boolean;
    enabled: Boolean;
  }) =>
    props.direction === "N"
      ? "50px 0 20px 0"
      : props.direction === "E"
      ? "0px 50px 0px 20px"
      : props.direction === "O"
      ? "0px 20px 0px 50px"
      : "20px 0 50px 0px"};
  margin: 2px;
  color: ${(props: { direction: string; active: Boolean; enabled: Boolean }) =>
    props.active ? "white" : null};
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: ${(props: { direction: string; active: Boolean; enabled: Boolean }) =>
    !props.enabled ? null : "pointer"};
  border: 1px solid rgba(0, 0, 0, 0.125);

  ${(props) =>
    props.enabled
      ? `&: hover{
        background - color: white; //${(props: {
          direction: string;
          active: Boolean;
          enabled: Boolean;
        }) => (!props.active ? "#007bff" : "white")};
        //color: ${(props: {
          direction: string;
          active: Boolean;
          enabled: Boolean;
        }) => (props.active ? "#212529" : "white")};
        border: 1px solid ${(props: {
          direction: string;
          active: Boolean;
          enabled: Boolean;
        }) => (!props.active ? "#007bff" : "rgba(0,0,0,.125)")};
        }`
      : ""}
`;

const CompleteInhibition = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props: { active: Boolean }) =>
    props.active ? "#dc3545" : "white"};
  border-radius: 50%;
  position: absolute;
  top: 44px;
  left: 44px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.125);

  &:hover {
    background-color: ${(props: { active: Boolean }) =>
      !props.active ? "#dc3545" : "white"};
    color: white;
    border: 1px solid
      ${(props: { active: Boolean }) =>
        !props.active ? "#dc3545" : "rgba(0,0,0,.125)"};
  }
`;

const InhibitionDirectionsComponent = (props: any) => {
  const [t, i18n] = useTranslation("common");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        transform: "rotate(45deg)",
        width: "fit-content",
        position: "relative",
      }}
    >
      <OverlayTrigger
        key={"omni_inhib"}
        placement="bottom"
        overlay={
          <Tooltip id="centerLocation">
            {t("widgets.inhibitor.inhibition-omni-hover")}
          </Tooltip>
        }
      >
        <CompleteInhibition
          active={
            Object.keys(props.directionalInhibitorDirections)
              .filter((k) => props.sectorsEnabled[k])
              .every((k) => props.directionalInhibitorDirections[k])
              ? true
              : false
          }
          onClick={() => {
            Object.keys(props.directionalInhibitorDirections)
              .filter((k) => props.sectorsEnabled[k])
              .every((k) => props.directionalInhibitorDirections[k])
              ? props.setDirectionalInhibitorDirections({
                  n: false,
                  e: false,
                  o: false,
                  s: false,
                })
              : props.setDirectionalInhibitorDirections({
                  n: props.sectorsEnabled["n"],
                  e: props.sectorsEnabled["e"],
                  o: props.sectorsEnabled["o"],
                  s: props.sectorsEnabled["s"],
                });
          }}
        />
      </OverlayTrigger>
      <div style={{ display: "flex" }}>
        <DirectionalInhibitorSector
          direction="N"
          active={props.directionalInhibitorDirections.n}
          enabled={props.sectorsEnabled["n"]}
          onClick={() =>
            props.sectorsEnabled["n"]
              ? props.setDirectionalInhibitorDirections({
                  ...props.directionalInhibitorDirections,
                  n: !props.directionalInhibitorDirections.n,
                })
              : null
          }
        >
          <span style={{ transform: "rotate(-45deg)" }}>
            {t("widgets.inhibitor.n-direction")}
          </span>
        </DirectionalInhibitorSector>
        <DirectionalInhibitorSector
          direction="E"
          active={props.directionalInhibitorDirections.e}
          enabled={props.sectorsEnabled["e"]}
          onClick={() =>
            props.sectorsEnabled["e"]
              ? props.setDirectionalInhibitorDirections({
                  ...props.directionalInhibitorDirections,
                  e: !props.directionalInhibitorDirections.e,
                })
              : null
          }
        >
          <span style={{ transform: "rotate(-45deg)" }}>
            {t("widgets.inhibitor.e-direction")}
          </span>
        </DirectionalInhibitorSector>
      </div>
      <div style={{ display: "flex" }}>
        <DirectionalInhibitorSector
          direction="O"
          active={props.directionalInhibitorDirections.o}
          enabled={props.sectorsEnabled["o"]}
          onClick={() =>
            props.sectorsEnabled["o"]
              ? props.setDirectionalInhibitorDirections({
                  ...props.directionalInhibitorDirections,
                  o: !props.directionalInhibitorDirections.o,
                })
              : null
          }
        >
          <span style={{ transform: "rotate(-45deg)" }}>
            {t("widgets.inhibitor.w-direction")}
          </span>
        </DirectionalInhibitorSector>
        <DirectionalInhibitorSector
          direction="S"
          active={props.directionalInhibitorDirections.s}
          enabled={props.sectorsEnabled["s"]}
          onClick={() =>
            props.sectorsEnabled["s"]
              ? props.setDirectionalInhibitorDirections({
                  ...props.directionalInhibitorDirections,
                  s: !props.directionalInhibitorDirections.s,
                })
              : null
          }
        >
          <span style={{ transform: "rotate(-45deg)" }}>
            {t("widgets.inhibitor.s-direction")}
          </span>
        </DirectionalInhibitorSector>
      </div>
    </div>
  );
};

export default InhibitorComp;
